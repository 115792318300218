import React, {useEffect, useState} from 'react';
import ApiDataBase from 'server'
import UseContext from "hooks/useContext";
import useFetchData from "../../../hooks/useFetchData";

const Activity = () => {
    const {userData} = useFetchData();
    const {data,setData}=UseContext()
    const [typesQualifications, setTypesQualifications] = useState([])
    const [isWaitingTypesQualifications, setIsWaitingTypesQualifications] = useState(true)
    const token = localStorage.getItem('xsrfToken')

    const qualificationsTypes = {
        'sale': {
            name: 'Vente',
            icon: '🛍',
            isSelected: false,
        },
        'food': {
            name: 'Restauration',
            icon: '🍳',
            isSelected: false,
        },
        'logistic': {
            name: 'Logistique',
            icon: '📦',
            isSelected: false,
        },
        'administration': {
            name: 'Administration',
            icon: '🧾',
            isSelected: false,
        },
        'largeDistribution': {
            name: 'Grande distribution',
            icon: '🛒',
            isSelected: false,
        },
        'industry': {
            name: 'Industrie',
            icon: '🏭',
            isSelected: false,
        },
        'other': {
            name: 'Autre',
            icon: '👷',
            isSelected: false,
        },
    };

    const [selectTypesQualifications, setSelectTypesQualifications] = useState(qualificationsTypes)
    const [listQualifications, setListQualifications] = useState([])
    const [selectedListQualifications, setSelectedListQualifications] = useState([])

    useEffect(() => {
        ApiDataBase.getListQualifications({token}, (data) => {
            setListQualifications(data)
        }, (err) => {
            console.warn(err)
        })
    }, [])

    const handleChoiceQ = (e) => {
        e.preventDefault()
        const value = e.target.value
        setData(prevData => ({...prevData, qualification: value}))
    }

    useEffect(() => {
        setIsWaitingTypesQualifications(true)
        ApiDataBase.getTypesQualifications({token}, (data) => {
            setTypesQualifications(data)
            setIsWaitingTypesQualifications(false)
        }, (err) => {
            console.log(err)
            setIsWaitingTypesQualifications(false)
        })
    }, []);

    useEffect(() => {
        //search for the selected qualification and return object
        const selectedQualification = Object.keys(selectTypesQualifications).find((key) => selectTypesQualifications[key].isSelected)
        //if there is a selected qualification
        if (selectedQualification) {
            const selectedQualificationData = qualificationsTypes[selectedQualification]
            setData(prevData=>({...prevData, activity: selectedQualificationData.name}))

            setSelectedListQualifications(listQualifications.filter((qualification) => qualification.type === selectedQualification))
        } else {
            setData(prevData=> ({...prevData, activity: ''}))
            setData(prevData=> ({...prevData, qualification: ''}))
            setSelectedListQualifications([])
        }
    }, [selectTypesQualifications]);

    return (
        <div className={'flex flex-col gap-6 px-8 mt-8'}>
            <div className={' flex flex-col gap-4'}>
                <span className='ft-xl font-medium text-gray-900'>Secteur d’activité</span>
                <div className={'flex flex-wrap mx-auto gap-6'}>
                    {isWaitingTypesQualifications ? (
                        [1, 2, 3, 4, 5].map((i, index) => (
                            <div key={index} className={`w-64 h-24 flex flex-col justify-center items-center gap-2 border rounded-md cursor-pointer`}>
                                <div className={'text-2xl'}>
                                    <div className={'h-8 bg-gray-200 rounded w-8'}></div>
                                </div>
                                <div className={''}>
                                    <div className={'h-3 bg-gray-200 rounded w-20'}></div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <>
                            {typesQualifications.map((type, index) => (
                                qualificationsTypes[type] && (
                                    <div key={index}
                                         className={`w-64 h-24 flex flex-col justify-center items-center gap-2 border rounded-md cursor-pointer ${selectTypesQualifications[type]?.isSelected ? 'border-yellow-500 bg-yellow-50' : 'border-gray-200'}`}
                                         onClick={() => {
                                             if (selectTypesQualifications[type]?.isSelected) {
                                                 setSelectTypesQualifications(prev => ({
                                                     ...prev,
                                                     [type]: {
                                                         ...prev[type],
                                                         isSelected: false
                                                     }
                                                 }))
                                             } else {
                                                 const newSelectTypesQualifications = {}
                                                 Object.keys(selectTypesQualifications).forEach((key) => {
                                                     newSelectTypesQualifications[key] = {
                                                         ...selectTypesQualifications[key],
                                                         isSelected: false
                                                     }
                                                 })
                                                 newSelectTypesQualifications[type] = {
                                                     ...selectTypesQualifications[type],
                                                     isSelected: true
                                                 }
                                                 setSelectTypesQualifications(newSelectTypesQualifications)
                                             }
                                         }}
                                    >
                                        <div className={'text-2xl'}>
                                            {qualificationsTypes[type].icon}
                                        </div>
                                        <div className={''}>
                                            {qualificationsTypes[type].name}
                                        </div>
                                    </div>
                                )
                            ))}
                        </>
                    )}
                </div>
            </div>
            <div>
                <span className='ft-xl font-medium text-gray-900'>Qualifications</span>
                <form className='mt-4'>
                    <select name="tool" size='1'
                            className="w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg  "
                            value={data.qualification}
                            onChange={handleChoiceQ}
                    >
                        <option value="">Selectionner le secteur</option>
                        {selectedListQualifications.map((qualification, index) => (
                            <option key={index} value={qualification.name}>{qualification.name}</option>
                        ))}
                    </select>
                </form>
            </div>

        </div>
    );
};

export default Activity;